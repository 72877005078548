
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PaymentIcon from '@mui/icons-material/Payment';
import MuiTextInput from '@mui/material/TextField';
import MuiSelectionInput from '@mui/material/Select';
import MuiOption from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/CheckCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    useRedirect,
    useCreate,
    useListContext,
    useDataProvider,
    useTranslate
} from 'react-admin'
import React from 'react';
import numberToWords from '../../providers/number-to-words';
const BulkPaymentCreateButton = ({
    related = 'students',
    deposit: initialDeposit = true,
}) => {
    const [open, setOpen] = React.useState(false);
    const { selectedIds, resource } = useListContext();
    const [sectorID, setSectorID] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [deposit, setDeposit] = React.useState(initialDeposit);
    const [note, setNote] = React.useState('');
    const [sectors, setSectors] = React.useState([]);
    const [confirmIntent, setConfirmIntent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    React.useEffect(() => {
        dataProvider.getList('sectors', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: {
                deposit: deposit,
                ...(related == 'students' ? { student_related: true } : related == 'employees' ? { employee_related: true } : {}),
            },
        }).then(({ data }) => {
            setSectors(data);
            setSectorID(data[0]?.id)
        }).catch((error) => {
            notify('bn.errors.network_error', { type: 'error' });
        });
    }, [deposit])
    const createMany = () => {
        setLoading(true);
        return dataProvider.createMany(
            'payments',
            {
                data: {
                    ...(related == 'students' ? { student_id: selectedIds } : related == 'employees' ? { employee_id: selectedIds } : {}),
                    amount: amount,
                    sector_id: sectorID,
                    note: note,
                }
            },
        ).then(({ data }) => {
            if (!data) throw new Error('bn.errors.no_data');
            notify('resources.payments.messages.create_success', { type: 'success' });
            unselectAll(resource);
            setOpen(false);
            return Promise.resolve();
        }).catch((error) => {
            notify(error, { type: 'error' });
        }).finally(() => {
            setLoading(false);
            setConfirmIntent(false);
        })
    }
    return open ? (<Dialog open={true} onClose={() => setOpen(false)}>
        <DialogContent>
            <h3>Fee Payment</h3>
            <FormControl fullWidth>
                <InputLabel id="bulk-payment-deposit">{translate('resources.sectors.fields.deposit')}</InputLabel>
                <MuiSelectionInput
                    label={translate('resources.sectors.fields.deposit')}
                    value={deposit}
                    onChange={(e) => setDeposit(e.target.value)}
                    error={deposit == null}
                >
                    <MuiOption value={true}>{translate('resources.transactions.fields.deposit')}</MuiOption>
                    <MuiOption value={false}>{translate('resources.transactions.fields.withdraw')}</MuiOption>
                </MuiSelectionInput>
            </FormControl>
            <br />
            <FormControl fullWidth>

                <InputLabel id="bulk-payment-sector">{translate('resources.payments.fields.sector_id')}</InputLabel>
                <MuiSelectionInput
                    label={translate('resources.payments.fields.sector_id')}
                    value={sectorID}
                    onChange={(e) => setSectorID(e.target.value)}
                    error={sectorID == null}
                >
                    {sectors.map(sector => <MuiOption key={sector.id} value={sector.id}>{sector.name}</MuiOption>)}
                </MuiSelectionInput>

            </FormControl>
            <MuiTextInput
                label={translate('resources.payments.fields.amount')}
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                error={!amount}
            //errorText={!amount ? translate('resources.payments.fields.amount') + ' is required' : null}
            />
            <b>
                {numberToWords.convert(amount)}
            </b>
            <MuiTextInput
                label={translate('resources.transactions.fields.note')}
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
            />

        </DialogContent>
        <DialogActions>
            <Button
                label="ra.action.cancel"
                onClick={() => setOpen(false)}
                disabled={loading}
                variant="contained"
                color='error'
            ><CancelIcon /></Button>
            <Button
                label="ra.action.save"
                onClick={e => setConfirmIntent(true)}
                disabled={loading}
                variant="contained"
                color="success"
                autoFocus
            ><SaveIcon /></Button>
            <Confirm
                isOpen={confirmIntent}
                loading={loading}
                title={translate('resources.payments.messages.bulk_create_confirm_title')}
                content={translate('resources.payments.messages.bulk_create_confirm_message')}
                onConfirm={createMany}
                onClose={() => {
                    setConfirmIntent(false);
                    //setOpen(false);
                }}
            />
        </DialogActions>
    </Dialog >) : <Button
        label="resources.payments.action.create"
        onClick={() => setOpen(true)}
        disabled={loading}
        variant="contained"
        color="primary"
    >
        <PaymentIcon />
    </Button>

}
export default BulkPaymentCreateButton;