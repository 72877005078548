import { Datagrid, List, TextField, DateField, ReferenceField, Show, SimpleShowLayout, RichTextField, usePermissions, SimpleList, ImageField, useTranslate, ChipField, FunctionField, useListContext, useGetList, Loading } from 'react-admin';
import { SelectField, Forbidden, ROLE_SUPER_ADMIN } from '../providers/forbidden';
import LoadingPanel from './LoadingPage.jsx';
import CONSTANTS from './Constants.ts';
import LogIcon from "@mui/icons-material/History";
import StudentIcon from "@mui/icons-material/School";
import EmployeeIcon from "@mui/icons-material/Badge";
import InstitutionIcon from "@mui/icons-material/Business";
import ExamIcon from "@mui/icons-material/HistoryEdu";
import ClassIcon from "@mui/icons-material/Class";
import PaymentIcon from "../assets/hand-money-icon.jsx";
import TransactionIcon from "@mui/icons-material/Money";
import { useRedirect } from 'react-admin';
const actionify = (action) => action && `resources.logs.actions.${action}`;
const ActionField = ({ record }) => {
    const action = actionify(record.action);
    const translate = useTranslate();
    return <ChipField>
        {translate(action)}
    </ChipField>
}
const selectIcon = ({ reference_id, reference_table }) => {
    //const translate = useTranslate();
    var Icon = LogIcon;
    switch (reference_table) {
        case 'students':
            Icon = StudentIcon;
            break;
        case 'employees':
            Icon = EmployeeIcon;
            break;
        case 'institutions':
            Icon = InstitutionIcon;
            break;
        case 'exams':
            Icon = ExamIcon;
            break;
        case 'classes':
            Icon = ClassIcon;
            break;
        case 'payments':
            Icon = PaymentIcon;
            break;
        case 'transactions':
            Icon = TransactionIcon;
            break;
        default:
            Icon = LogIcon;
    }
    return <Icon />
}
const Lg = () => {
    const { data } = useListContext();
    const employeeIDs = data?.map(({ performed_by }) => performed_by);
    const {data : employeeData, isFetched} = useGetList('employees', {filter : { ids: [...new Set(employeeIDs)] }});
    const employeeMap = employeeData?.reduce((acc, {id, name}) => ({...acc, [id] : name}), {});
    const translate = useTranslate();
    if (!isFetched) return <Loading />
    return <SimpleList
        primaryText={record => translate(actionify(record.action))}
        leftIcon={selectIcon}
        tertiaryText={record => <DateField {...CONSTANTS.timeStampOptions} record={record} source="createdAt" />}
        secondaryText={record => <b>{employeeMap[record.performed_by]}</b>
        }
        linkType="show"
    />
}
export const LogList = () => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const translate = useTranslate();
    return (
        permissionLoading ? <LoadingPanel /> : permissions?.logs?.list ?
            <List>
                <Lg />
            </List> : <Forbidden />
    );
}
export const LogShow = () => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const translate = useTranslate();
    return (
        permissionLoading ? <LoadingPanel /> : permissions?.logs?.show ? <Show>
            <SimpleShowLayout>

                <TextField source="id" />
                <FunctionField label='resources.logs.fields.action' render={record => <h2>{translate(actionify(record.action))}</h2>} />
                <ReferenceField source="performed_by" reference="employees" link='show'>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="inst_id" reference="institutions" link={false}>
                    <TextField source='name' />
                </ReferenceField>
                <DateField source="timestamp" {...CONSTANTS.timeStampOptions} />
                <RichTextField source="note" />
            </SimpleShowLayout>
        </Show> : <Forbidden />
    );
}