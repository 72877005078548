import React from 'react';
import {
    List, Datagrid, TextField, DateField, EditButton,
    Edit, SimpleForm, TextInput, DateInput, Create,
    Filter, ReferenceInput, SelectInput, ReferenceField,
    Show, SimpleShowLayout,
    Delete, NumberField, NumberInput,
    DeleteButton, maxValue, minValue,
    ListButton, TopToolbar,
    DateTimeInput, ExportButton,
    BooleanInput, useQueryWithStore,
    BooleanField, required, RichTextField, CreateButton, useTranslate, TabbedShowLayout, usePermissions, NullableBooleanInput, useDataProvider, useNotify, FunctionField, useRecordContext, useListContext, LoadingPage, downloadCSV
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Forbidden, Box, Typography, Red as RED, GREEN, ROLE_ACCOUNTANT, ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../providers/forbidden';
import NumToBangla from '../providers/number-to-words';
import DocumentTab from './documents/documentTab';
import AddMoreImage from './imageInput';
import CONSTANTS from './Constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import numberToWords from '../providers/number-to-words';
const ShowLayoutToolbar = ({ basePath, data, resource }) => (
    <TopToolbar>
        <CreateButton />
        <ListButton />
    </TopToolbar>
);
const TransactionExporter = async (transactions, fetchRelatedRecords, dataProvider) => {
    try {
        const sectors = await fetchRelatedRecords(transactions, 'sector_id', 'sectors')
        const students = await fetchRelatedRecords(transactions, 'student_id', 'students')
        const employees = await fetchRelatedRecords(transactions, 'employee_id', 'employees')
        const payments = await fetchRelatedRecords(transactions, 'payment_id', 'payments')
        var totalDeposit = 0, totalWithdraw = 0, totalBalance = 0;
        const Report = <table style={{ border: '1px solid black', tableLayout: 'fixed', borderCollapse: 'collapse', width: '100%', textAlign: 'center' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>ক্রমিক নং</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>তারিখ</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>খাত</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>পেমেন্ট আইডি</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>জমা</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>খরচ</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>ব্যালেন্স</th>
                    <th style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>বিস্তারিত</th>
                </tr>
            </thead>
            <tbody>
                {transactions?.map((transaction, index) => {
                    const sector = sectors[transaction?.sector_id];
                    const payment = transaction?.payment_id && payments[transaction?.payment_id];
                    const amount = transaction?.amount;
                    const isDeposit = sector.deposit;
                    if (isDeposit)
                        totalDeposit += amount;
                    else
                        totalWithdraw += amount;
                    totalBalance += amount;
                    return <tr key={index} style={{ background: isDeposit ? GREEN[200] : RED[200] }}>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{transaction.id}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{new Date(transaction?.timestamp).toLocaleDateString('bn-BD', CONSTANTS.timeStampOptions.options)}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{`${sector.name} (#${sector.id.toLocaleString('bn-BD')})`}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{payment?.id}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{isDeposit ? amount.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options) : ''}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{!isDeposit ? amount.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options) : ''}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{totalBalance.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options)}</td>
                        <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }}>{transaction?.note}</td>
                    </tr>
                })}
                <tr>
                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word' }} colSpan={4}>মোট</td>
                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word', background: GREEN[300] }}>{totalDeposit.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options)}</td>
                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word', background: RED[300] }}>{totalWithdraw.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options)}</td>
                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word', background: totalBalance < 0 ? RED[300] : GREEN[300] }}>{totalBalance.toLocaleString('bn-BD', CONSTANTS.currencyOptions.options)}</td>
                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', wordWrap: 'break-word', background: totalBalance < 0 ? RED[300] : GREEN[300] }}>{numberToWords.convert(totalBalance)}</td>
                </tr>
            </tbody>
        </table>;
        dataProvider.setPrintComponent(Report);
    } catch (e) {
        console.error(e);
    }
    return downloadCSV
}

const TransactionFilter = [
    <ReferenceInput source="sector_id" reference="sectors" alwaysOn>
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateInput source="date_from" locales="bn-BD" alwaysOn validate={maxValue(CONSTANTS.toLocalDate(new Date(new Date().setDate(new Date().getDate() - 1))), "সর্বোচ্চ গতকালের তারিখ")} />, // Maximum value must be yesterday
    <DateInput source="date_to" alwaysOn validate={maxValue(CONSTANTS.toLocalDate(new Date()), "সর্বোচ্চ আজকের তারিখ")} />,
    <NullableBooleanInput
        source="deposit"
        alwaysOn
    />
]
export const TransactionList = ({ permissions, ...props }) => {
    const Transactions = props => {
        const { data, total } = useListContext();
        const translate = useTranslate('bn');
        let totalAmount = 0, totalCount = total;
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        if (data && Object.keys(data).length) {
            for (let id in data) {
                if (typeof data[id].total_amount == 'undefined')
                    continue;
                totalAmount = data[id].total_amount;
                break;
            }
        }
        return (
            <>
                <Box>
                    <Typography
                        style={{
                            margin: '10px',
                            padding: '10px',
                            background: totalAmount < 0 ? RED[50] : GREEN[50],
                            color: totalAmount < 0 ? RED[900] : GREEN[900],
                        }}
                        variant="h6"
                        gutterBottom
                    >
                        {translate('resources.transactions.fields.total_amount')} : <b>{totalAmount.toLocaleString('bn-BD', { style: 'currency', currency: 'BDT', minimumFractionDigits: 0 })}</b>
                    </Typography>
                </Box>
                <Datagrid {...props} rowClick="show">
                    <NumberField source="id" {...CONSTANTS.locales} />
                    <ReferenceField source="sector_id" reference="sectors" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    {!isSmall && <ReferenceField source="payment_id" reference="payments" link={false} />}
                    {/* {permissions && permissions.transactions.READ && (
                        <ReferenceField source="student_id" reference="students" link="show" >
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )}
                    {permissions && permissions.transactions.READ && (
                        <ReferenceField source="staff_id" reference="staffs" link='show'>
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )}
                    {permissions && permissions.transactions.READ && (
                        <ReferenceField source="registered_by" reference="staffs" link="show">
                            <TextField source="name_bn" />
                        </ReferenceField>
                    )} */}
                    {/* 
                    {1 && <TextField source="payee" />} */}
                    {1 && <NumberField source="amount" {...CONSTANTS.currencyOptions} />}
                    <DateField source="timestamp" {...CONSTANTS.timeStampOptions} />
                </Datagrid>
            </>

        );
    }
    return (
        1 ? <List perPage={10000} filters={TransactionFilter} exporter={TransactionExporter}>
            <Transactions />
        </List> : <Forbidden />
    );
}
const TransactionCreateToolbar = props => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);
export const TransactionCreate = (props) => {
    const { search: searchString } = useLocation()
    const search = new URLSearchParams(searchString);
    const [associatedWithStudent, setAssociatedWithStudent] = React.useState(!!search.get('student_id'));
    const [associatedWithEmployee, setAssociatedWithEmployee] = React.useState(!!search.get('employee_id'));
    const [misc, setMisc] = React.useState((!search.get('student_id') && !search.get('employee_id')));
    const [isDeposit, setIsDeposit] = React.useState(true);
    const [payeeName, setPayeeName] = React.useState('');
    const [studentID, setStudentID] = React.useState(search.get('student_id') && parseInt(search.get('student_id')));
    const [employeeID, setEmployeeID] = React.useState(search.get('employee_id') && parseInt(search.get('employee_id')));
    const [amount, setAmount] = React.useState(search.get('amount') && parseInt(search.get('amount')));
    const [amountFixed, setAmountFixed] = React.useState(!!search.get('amount'));
    const [sectorFixed, setSectorFixed] = React.useState(!!search.get('sector_id'));
    const [paymentID, setPaymentID] = React.useState(search.get('payment_id') && parseInt(search.get('payment_id')))
    const [sectorID, setSectorID] = React.useState(search.get('sector_id') && parseInt(search.get('sector_id')));
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { permissions, isLoading: permissionLoading } = usePermissions();
    // React.useEffect(() => {
    //     if(p_id)
    //         setPaymentID(p_id);
    // }, [p_id])
    // Fetching payment info and auto filling the form
    React.useEffect(() => {
        if (paymentID) {
            dataProvider.getOne('payments', { id: paymentID })
                .then(({ data }) => {
                    setSectorID(data.sector_id);
                    setAmount(data.amount);
                    setAmountFixed(true);
                    setSectorFixed(true);
                }).catch(error => {
                    setAmountFixed(false);
                    notify(error?.message, { type: 'error' });
                })
        } else {
            setSectorID(null);
        }
    }, [paymentID]);
    React.useEffect(() => {
        if (!search.get('payment_id') && !studentID && !employeeID) {
            setPaymentID(null);
            setSectorFixed(false);
        }
    }, [studentID, employeeID])
    // Fetching student info and auto filling the form
    React.useEffect(() => {
        if (!studentID || !employeeID) {
            setPaymentID(null);
        }
    }, [studentID, employeeID]);
    return permissionLoading ? <LoadingPage /> : (
        permissions?.transactions?.create ? <Create actions={<TransactionCreateToolbar />}>
            <SimpleForm>
                <SelectInput
                    source="deposit"
                    choices={[
                        { id: true, name: 'resources.transactions.fields.deposit' },
                        { id: false, name: 'resources.transactions.fields.withdraw' },
                    ]}
                    label="resources.transactions.fields.type"
                    validate={required()}
                    defaultValue={isDeposit}
                    onChange={e => setIsDeposit(e.target.value)}
                    fullWidth
                    disabled={!!search.get('deposit')}
                />
                {isDeposit !== null && <SelectInput
                    source="related"
                    choices={[
                        { id: 'student', name: 'resources.sectors.fields.student_related' },
                        { id: 'employee', name: 'resources.sectors.fields.employee_related' },
                        { id: 'other', name: 'resources.sectors.fields.other' },
                    ]}
                    onChange={e => {
                        setAssociatedWithEmployee(e.target.value == 'employee');
                        setAssociatedWithStudent(e.target.value == 'student');
                        setMisc(e.target.value == 'other');
                    }}
                    label="resources.sectors.fields.related"
                    validate={required()}
                    fullWidth
                    defaultValue={associatedWithStudent ? 'student' : associatedWithEmployee ? 'employee' : 'other'}
                    disabled={!!search.get('sector_id')}
                />}

                {associatedWithStudent && (
                    <ReferenceInput filter={{
                        ...(isDeposit ? { payment_due: true } : {})
                    }} validate={required()} source="student_id" reference="students" label="resources.students.name" >
                        <SelectInput defaultValue={studentID} value={studentID} fullWidth onChange={e => setStudentID(e.target.value)} optionText="name_bn" disabled={!!search.get('student_id')} />
                    </ReferenceInput>
                )
                }

                {associatedWithEmployee &&
                    <ReferenceInput filter={{
                        ...(!isDeposit ? { payment_due: true } : {})
                    }} validate={required()} source="employee_id" reference="employees" label="resources.staffs.name" >
                        <SelectInput value={employeeID} defaultValue={employeeID} onChange={e => setEmployeeID(e.target.value)} optionText="name" disabled={!!search.get('employee_id')} />
                    </ReferenceInput>
                }
                {(paymentID || associatedWithEmployee || associatedWithStudent) && <ReferenceInput filter={{
                    ...(isDeposit ? { status: 'due' } : {}),
                    ...(associatedWithStudent ? { student_id: studentID } : {}),
                    ...(associatedWithEmployee ? { employee_id: employeeID } : {}),
                }} source="payment_id" reference="payments" label="resources.payments.name" >
                    <SelectInput fullWidth translateChoice={false} defaultValue={paymentID} optionText="id" validate={required()} onChange={e => setPaymentID(e.target.value)} disabled={!!search.get('payment_id')} />
                </ReferenceInput>
                }
                {(studentID || employeeID || misc) && <ReferenceInput source="sector_id" filter={{
                    deposit: isDeposit,
                    ...(associatedWithStudent ? { student_related: true } : {}),
                    ...(associatedWithEmployee ? { employee_related: true } : {}),
                    ...(misc ? { misc: true } : {}),
                    ...(paymentID ? { payment_id: paymentID } : {}),

                }} reference="sectors" label="resources.sectors.name" >
                    <SelectInput fullWidth translateChoice={false} optionText="name" defaultValue={sectorID} disabled={sectorFixed} validate={required()} />
                </ReferenceInput>
                }
                {misc && <TextInput fullWidth source="payee" label="resources.transactions.fields.payee" value={payeeName} onChange={e => setPayeeName(e.target.value)} />}
                {misc && <TextInput fullWidth source="payee_phone" label="resources.transactions.fields.payee_phone" />}
                <TextInput
                    validate={required()}
                    source="amount"
                    label="resources.transactions.fields.amount"
                    inputMode='numeric'
                    fullWidth
                    {...(amountFixed ? { disabled: true, defaultValue: amount } : {value : amount})}
                    onChange={e => !amountFixed && setAmount(e.target.value)}
                />
                <b>
                    {NumToBangla.convert(amountFixed || amount || 0)}
                </b>
                <TextInput
                    source="note"
                    label="resources.transactions.fields.note"
                    multiline
                    defaultValue={payeeName !== '' ? ('পরিশোধ করেছেন : ' + payeeName) : ''}
                    fullWidth
                />
                <AddMoreImage multiple source="documents" name="লেনদেনের নথিপত্র" />
            </SimpleForm>
        </Create> : <Forbidden />
    )
}

export const TransactionShow = props => {
    const { permissions, isLoading: permissionLoading } = usePermissions()
    return permissionLoading ? <LoadingPage /> : (
        permissions?.transactions?.list ? <Show actions={<ShowLayoutToolbar />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="resources.transactions.tabs.details">
                    <NumberField source="id" {...CONSTANTS.locales} />
                    <NumberField source="amount" {...CONSTANTS.currencyOptions} />
                    <ReferenceField source="sector_id" reference="sectors" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="payment_id" reference="payments" link="show">
                        <FunctionField render={record => `${record.id} (${record.status})`} />
                    </ReferenceField>
                    {/* <TextField source="payee" /> */}
                    <RichTextField source="note" />
                    <DateField source="timestamp" {...CONSTANTS.timeStampOptions} />
                    <ReferenceField source="registered_by" reference="employees" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                </TabbedShowLayout.Tab>
                <DocumentTab target="transaction_id" />
            </TabbedShowLayout>
        </Show> : <Forbidden />);
};