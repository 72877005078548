import React from 'react';
import {
    List, Edit, Create, Datagrid, TextField, EditButton,
    ImageField,
    SimpleForm, TextInput, SelectInput, ReferenceInput,
    ReferenceField, Filter, Responsive, SimpleList,
    DateField, DateInput, BooleanInput,
    Show, SimpleShowLayout, ShowButton,
    DeleteButton, ArrayInput, SimpleFormIterator,
    Delete, required, ArrayField,
    regex, number, CheckboxGroupInput,
    minLength,
    maxLength,
    ImageInput,
    RichTextField,
    NumberField,
    Button,
    FileInput,
    TopToolbar,
    ListButton,
    NumberInput,
    CreateButton,
    UrlField,
    SingleFieldList,
    PasswordInput,
    minValue, ChipField, useTranslate, usePermissions, FunctionField, TabbedShowLayout, ReferenceArrayField, ReferenceManyField, TabbedForm, ExportButton, LoadingPage, useRecordContext, WrapperField, useInput, Confirm, Loading, useGetIdentity
} from 'react-admin';
import Card from '@mui/material/Card';
import ShieldIcon from '@mui/icons-material/Shield';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
// import { BooleanNumField as BooleanField } from '../boolean';
import CONSTANTS from '../Constants.ts';
import { Forbidden } from '../../providers/forbidden.js';
import DocumentTab from '../documents/documentTab.js';
import AddMoreImage from '../imageInput.js';
import PaymentTab from '../payments/paymentTab.js';
import Chip from '@mui/material/Chip';
import BulkPaymentCreateButton from '../payments/bulkfee.js';
const PermissionEdit = React.lazy(() => import('./EmployeePermissionEdit.jsx'));
const phoneNumberValidator = [
    number(),
    minLength(11),
    maxLength(11),
    regex(/01[0-9]{9}/, "ra.validation.phone_number")
];

const EditLayoutToolbar = () => (
    <TopToolbar>
        <ShowButton />
        <ListButton />
    </TopToolbar>
);

const EditSection = ({ permissions }) => {
    const record = useRecordContext();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const [permissionDialogOpen, setPermissionDialogOpen] = React.useState(false);
    const updatingSelf = identity?.id === record?.id;
    if (identityLoading) return <LoadingPage />
    if (updatingSelf && !permissions?.employees?.updateOwn) return <Forbidden />
    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="resources.employees.tabs.personal">
                <SimpleForm>
                    <Stack direction="row" spacing={2}>
                        <Card sx={{ width: '50%' }}>
                            <TextInput source="name" validate={required()} /><br />
                            <NumberInput source="nid_no" validate={required()} /><br />
                            <TextInput source="permanent_address" validate={required()} /><br />
                            <TextInput source="present_address" validate={required()} /><br />
                            <TextInput source="phone" validate={[required(), ...phoneNumberValidator]} /><br />
                            <SelectInput source="blood_group" choices={CONSTANTS.BloodGroups} validate={required()} fullWidth /><br />
                            <TextInput source='note' multiline defaultValue={'মোবাইল ২:\nমোবাইল ৩:'} validate={maxLength(255, "সবমিলিয়ে সর্বোচ্চ ২৫৫ টি অক্ষর হতে হবে")} /><br />

                            <TextInput
                                source="password"
                                type="password"
                                disabled={!updatingSelf && !permissions?.employees?.updateOtherPassword}
                                inputProps={{
                                    minLength: 6,
                                    maxLength: 20,
                                }}
                            /><br />

                        </Card>
                        <Card>

                            <AddMoreImage source="photograph_id" label="resources.employees.fields.photo" />
                            <AddMoreImage source="nid_document_id" label="resources.employees.fields.nid_no" />
                            <AddMoreImage multiple source="documents" label="resources.documents.name" />
                        </Card>
                    </Stack>
                </SimpleForm>
            </TabbedShowLayout.Tab>
            {permissions?.employees?.updateOfficial && <TabbedShowLayout.Tab label="resources.employees.tabs.official">
                <SimpleForm>
                    <Stack direction="row" spacing={2}>
                        <Card sx={{ width: '50%' }}>
                            <CheckboxGroupInput source="qualifications" choices={CONSTANTS.EmployeeQualifications} translate={true} /><br />
                            <TextInput source="title" validate={required()} /><br />
                            <SelectInput
                                source="role"
                                choices={CONSTANTS.ROLES}
                                validate={required()}
                                disabled={!permissions?.employees?.updatePermissions}
                                fullWidth
                            /><br />
                            <NumberInput source="salary" validate={required()} {...CONSTANTS.currencyOptions} /> <br />
                            <TextInput source="previous_institutions" validate={required()} multiline /> <br />
                            <DateInput source="joined_at" validate={required()} fullWidth /> <br />
                            <TextInput source="leaving_reason" multiline /> <br />
                            <DateInput source="left_at" fullWidth /> <br />
                        </Card>
                        <Card>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ m: 1 }}
                                color='warning'
                                disabled={!permissions?.employees?.updatePermissions || permissionDialogOpen}
                                onClick={() => setPermissionDialogOpen(true)}
                                label="resources.employees.fields.permissions"><ShieldIcon /></Button>
                            {permissionDialogOpen && <PermissionEdit setPermissionDialogOpen={setPermissionDialogOpen} />}
                            <AddMoreImage multiple source="documents" label="resources.documents.name" />

                        </Card>
                    </Stack>
                </SimpleForm>
            </TabbedShowLayout.Tab>
            }
        </TabbedShowLayout>
    )
}
export const EmployeeEdit = () => {
    const { permissions, isLoading } = usePermissions();
    return isLoading ? <Loading /> : (
        permissions?.employees?.update ? <Edit mutationMode='pessimistic' actions={<EditLayoutToolbar />}>
            <EditSection permissions={permissions} />
        </Edit> : <Forbidden />
    );
}



////////////////////////// Create //////////////////////////

const CreateLayoutToolbar = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const EmployeeCreate = () => {
    const translate = useTranslate('bn');
    const { permissions, isLoading: permissionLoading } = usePermissions()
    return permissionLoading ? <Loading /> : permissions?.employees?.create ? (
        <Create actions={<CreateLayoutToolbar />} redirect='show'>
            <SimpleForm>
                <Stack direction="row" spacing={2}>
                    <Card sx={{ width: '50%' }}>
                        <TextInput source="name" validate={required()} /><br />
                        <TextInput source="father" validate={required()} /><br />
                        <TextInput source="mother" validate={required()} /><br />
                        <DateInput source="date_of_birth" validate={required()} fullWidth /><br />
                        <SelectInput source="accomodation" choices={CONSTANTS.EmployeeResidentialStatus}
                            validate={[required()]}
                            fullWidth
                        /><br />
                        <SelectInput source="blood_group" choices={CONSTANTS.BloodGroups} validate={required()} fullWidth /><br />
                        <NumberInput source="nid_no" validate={required()} /><br />
                        <TextInput source="permanent_address" validate={required()} /><br />
                        <TextInput source="present_address" validate={required()} /><br />
                        <TextInput source="phone" validate={[required(), ...phoneNumberValidator]} /><br />
                        <CheckboxGroupInput source="qualifications" choices={CONSTANTS.EmployeeQualifications} translate={true} /><br />
                        <TextInput source="title" validate={required()} /><br />
                        <TextInput source="previous_institutions" multiline /><br />
                        <TextInput source="password" type="password" validate={required()} inputProps={{
                            minLength: 6,
                            maxLength: 20,
                        }} />
                    </Card>
                    <Card>
                        <TextInput source='note' multiline defaultValue={'মোবাইল ২:\nমোবাইল ৩:'} validate={maxLength(255, "সবমিলিয়ে সর্বোচ্চ ২৫৫ টি অক্ষর হতে হবে")} /><br />
                        <AddMoreImage source="photograph_id" label="resources.employees.fields.photo" name='কর্মচারীর ছবি' />
                        <AddMoreImage source="nid_document_id" label="resources.employees.fields.nid_no" name='কর্মচারীর জাতীয় পরিচয়পত্র' />
                        <AddMoreImage multiple source="documents" label="resources.employees.fields.documents" name='কর্মচারীর বিবিধ নথিপত্র' />
                    </Card>
                </Stack>
            </SimpleForm>
        </Create>
    ) : <Forbidden />

}


/******************************************************888888888888888 SHOW ******************** */
const ShowLayoutToolbar = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton />
        <ListButton />
    </TopToolbar>
);
const QualificationField = ({ source }) => {
    const translate = useTranslate();
    const record = useRecordContext();

    return (
        <WrapperField label={translate('resources.employees.fields.qualifications')}>
            {
                record && record[source]?.map((qualification, index) => (
                    <Chip sx={{ display: 'inline-block' }} key={index} label={translate(`resources.employees.fields.qualifications.${qualification}`)} />
                ))
            }
        </WrapperField>
    )
}
export const EmployeeShow = () => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const translate = useTranslate();
    return 1 ? (
        <Show actions={<ShowLayoutToolbar />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="resources.employees.tabs.personal">
                    <ReferenceField source="photograph_id" reference="documents" link={false}>
                        <ImageField source="url" />
                    </ReferenceField>
                    <NumberField source="id" />
                    <TextField source="name" />
                    <TextField source="phone" />
                    <DateField source="date_of_birth" {...CONSTANTS.dateOptions} />
                    <TextField source="father" />
                    <TextField source="mother" />
                    <NumberField source="nid_no" />
                    <TextField source="blood_group" />
                    <RichTextField source='note' />
                    <RichTextField source="present_address" />
                    <RichTextField source="permanent_address" />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="resources.employees.tabs.official">
                    <NumberField source="due_amount" {...CONSTANTS.currencyOptions} />
                    <TextField source="title" />
                    <NumberField source="salary" {...CONSTANTS.currencyOptions} />
                    <DateField source="joined_at" {...CONSTANTS.dateOptions} />
                    <FunctionField render={ql => <ChipField record={{ id: translate(CONSTANTS.EmployeeResidentialStatusMap[ql.accomodation].name) }} source='id' />} />
                    <ArrayField source='qualifications'>
                        <SingleFieldList>
                            <FunctionField render={ql => <ChipField record={{ id: translate(CONSTANTS.Qualifications[ql].name) }} source='id' />} />
                        </SingleFieldList>
                    </ArrayField>
                    <DateField source="left_at" {...CONSTANTS.dateOptions} />
                    <RichTextField source="leaving_reason" />
                </TabbedShowLayout.Tab>
                <PaymentTab target="employee_id" />
                <DocumentTab target="employee_id" />
            </TabbedShowLayout>
        </Show>) : <Forbidden />
}
/**********************************88888888 LIST  */
const EmployeeListBulkButtons = props => (
    <>
        <ExportButton />
        <BulkPaymentCreateButton related='employees' deposit={false} />
        <CreateButton />
    </>
)
export const EmployeeList = () => {
    const { permissions, isLoading: permissionLoading } = usePermissions();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return !permissionLoading && permissions || 1 ? (
        <List >
            <Datagrid rowClick="show" bulkActionButtons={<EmployeeListBulkButtons />}>
                <NumberField source="id" />
                <TextField source="name" />
                <TextField source="phone" />
                {!isSmall && <TextField source="title" />}
                {!isSmall && <NumberField source="salary" {...CONSTANTS.currencyOptions} />}
                {!isSmall && <EditButton />}
            </Datagrid>
        </List>
    ) : <Forbidden />;
}
